

















































































  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import CusTable from "@/components/table";
  import MixinEdu from "@/views/education/Mixins/mixin-edu";
  import axios from "@/core/axios";

  @Component({
    mixins: [ MixinEdu ]
  })
  export default class extends Vue{
    private subjectArr: any[] = [];
    private rowId = ''
    private tableRow: any = {}
    private options: any[] = [{}, {}, {}]; //学科
    private subjects: any[] = []; //新增时学科
    private subjectsEdit: any[] = []; //修改时学科
    private AddDialogVisible = false
    private inputVal = ''
    private subjectList = []
	  private jyQuestionTypeNameList: any = []
	  private param = {
      subjectCode: '',
      jyQuestionTypeName: '',
    }
    private formData: any = {
      questionTypeName: '',
      basicQuestionType: { name: '', id: ''},
      subjects: [],
    }
    private selectMap = {
      knowledgeList: [],
    }
    $refs!: { table: CusTable, addCascader: any }
    
    @Watch('subjectArr')
    subjectArrChange(e) {
      this.param.subjectCode = this.subjectArr[1] 
    }
    subjectScope(e) {
      let name 
      this.options.map((item) => {
        if(item.children && item.children.length > 0){
          item.children.map((item1) => {
            if(e === item1.value){
              name = item1.name
            }
          })
        }
      })
      return name
    }
    private async getQuestionType() {
      const res: any = await this.axios.post('/tiku/questionType/queryAllBasicQuestionType');
      if(res.result){
        this.jyQuestionTypeNameList = res.json 
      }
    }
    private created() {
      this.initData();
    }
    private async getSubject() {
      const res: any = await this.axios.post('/system/dictionary/querySubject');
      if (res.result) {
        Object.keys(res.json).forEach((key, index) => {
          let subjectCode = ''
          switch (key) {
            case 'high':
              subjectCode = '高中'
              break;
            case 'middle':
              subjectCode = '初中'
              break;
            case 'primary':
              subjectCode = '小学'
              break;
            default:
              subjectCode = '小学'
              break;
          }
          this.$set(this.options[index], 'value', key)
          this.$set(this.options[index], 'label', subjectCode)
          this.$set(this.options[index], 'children', res.json[key])
        });
        this.options.forEach((item: any, index: number) => {
          item.children.forEach((key: any) => {
            this.$set(key, 'value', key.code)
            this.$set(key, 'label', key.name)
          });
        });
        this.subjectArr[0] = this.options[0].value;
        this.subjectArr[1] = this.options[0].children[0].value;
        this.param.subjectCode = JSON.parse(JSON.stringify(this.subjectArr[1]))
      } 
      this.$refs.table.request(this.param)
    };
     private async initData() {
      await this.getSubject();
      this.getQuestionType();
    };
    private async addQuesSource() {
      if (this.formData.questionTypeName === '') {
        this.$message.warning('请输入题型名称')
        return false
      }
      else if (this.subjects.length === 0 && this.rowId === '') {
        this.$message.warning('请选择所属科目')
        return false;
      }
      else if (this.subjectsEdit.length === 0 && this.rowId) {
        this.$message.warning('请选择所属科目')
        return false;
      }
      else if (this.formData.basicQuestionType.name === '') {
        this.$message.warning('请选择逻辑题型')
        return false
      }
      else if (this.AddDialogVisible && this.rowId == '') { //新增
        this.jyQuestionTypeNameList.map((item: any, index) => {
          if(item.name === this.formData.basicQuestionType.name){
            this.formData.basicQuestionType.id = item.id
          }
        })
        let data = {
          name: '',
          code: ''
        }
        this.formData.subjects = []
        this.options.map((item) => {
          item.children.map((item1) => { //联动数据遍历
            this.subjects.map((item2) => { //显示数据遍历
              if(item2[1] === item1.value) {
                data = {
                  name: item1.name,
                  code: item1.value
                }
                this.formData.subjects.push(data)
              }
            })
          })
        })
        const res: any = await this.axios.post('/tiku/questionType/batchSaveQuestionTypeRelation', { ...this.formData },{headers:{'Content-Type':'application/json'}});
        if (res.result) {this.AddDialogVisible = !this.AddDialogVisible, this.$refs.table.request(); this.$message({type:'success', message: '新增成功'})}
      }
      else if (this.AddDialogVisible && this.rowId ) { //修改
        let toolQuestionType
        this.jyQuestionTypeNameList.map((item: any, index) => {
          if(item.name === this.formData.basicQuestionType.name){
            toolQuestionType = item.id
          }
        })
        let data = {
          id: this.rowId,
          jyQuestionTypeName: this.formData.questionTypeName,
          jyQuestionType: this.tableRow.jyQuestionType,
          toolQuestionTypeName: this.formData.basicQuestionType.name,
          toolQuestionType: toolQuestionType,
          subjectId: this.subjectsEdit[1]
        }
        const res: any = await this.axios.post('/tiku/questionType/modifyQuestionTypeRelation', data );
        if (res.result) {this.AddDialogVisible = !this.AddDialogVisible, this.$refs.table.request(); this.$message({type:'success', message: '修改成功'})}
      };
    }

    private selectionChange(ev) {
      this.selectedArr = ev;
    };
    
	  private timers:number = 0;
	  private searchInputChange() {
      clearTimeout(this.timers);
      setTimeout(() => {
        this.$refs.table.request(this.param)
      }, 600)
    }
	  private subjectChange() {
      clearTimeout(this.timers);
      setTimeout(() => {
        this.$refs.table.request(this.param)
      }, 600)
    }
    private handleEdit(index, row) {
      this.AddDialogVisible = true;
      this.rowId = row.id;
      this.tableRow = row
      this.formData = {
        questionTypeName:row.jyQuestionTypeName,
        basicQuestionType: {
          name: row.toolQuestionTypeName,
        }
      }
      this.options.map((item)=>{
        item.children.map((item1)=>{
          if(item1.value === row.subjectId){
            this.subjectsEdit = [item.value, row.subjectId]
          }
        })
      })
    }

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
      this.selectedArr = [row]
      this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table, '/tiku/questionType/batchChangeStatus')
    }

    handleDelete(idx, row) {
      this.selectedArr = [row]
      this.batchDeleteHandle(this.$refs.table, '/tiku/questionType/batchDelete');
    }

    private batchChangeStatusHandle(status) {
      this.batchChangeStatu(status, this.$refs.table, '/tiku/questionType/batchChangeStatus');
    };
    private dialogClose() {
      this.formData = {
        questionTypeName: '',
        basicQuestionType: { name: '', id: ''},
        subjects: [],
      }
      this.subjects = []
      this.subjectsEdit = []
      this.rowId =''
    }
  }
